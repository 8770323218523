import React from 'react';
import {Field} from "formik";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {extract} from "../../utils";

function BankRIBField({name, errors, touched}) {
  const {t} = useTranslation()
  return (
    <div
      className={clsx('form-control form-control-lg p-0 mb-4', {'is-invalid': extract(touched, name) && extract(errors, name)},
        {
          'is-valid': extract(touched, name) && !extract(errors, name),
        })}>
      <div className="d-flex">
        <Field
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.bank_code`}
          placeholder={t('Bank Code')}
          className={clsx(
            `form-control form-control-transparent w-150px`,
          )}
          style={{width: '20% !important;'}}
          id={`id_${name}_bank_code`}
        />
        <Field
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.agence_code`}
          placeholder={t('Agence Code')}
          className={clsx(
            `form-control form-control-transparent w-150px`,
          )}
          id={`id_${name}_agence_code`}
        />
        <Field
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.account_number`}
          placeholder={t('Account Number')}
          className={clsx(
            `form-control form-control-transparent`,
          )}
          id={`id_${name}_account_number`}
        />
        <Field
          // id={`id_${name}`}
          type={'text'}
          name={`${name}.rib_key`}
          placeholder={t('RIB Key')}
          className={clsx(
            `form-control form-control-transparent  w-70px`,
          )}
          id={`id_${name}_rib_key`}
        />
      </div>
    </div>
  );
}

export default BankRIBField;
