import React from 'react';
import {Field} from "formik";
import Flatpickr from "react-flatpickr";
import {formatISO, parseISO} from 'date-fns';
import I18n from "../../i18n";

function DateField({name, placeholder}) {
  return (
    <Field name={name}>
      {({
          field, // { name, value, onChange, onBlur }
          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => {
        // console.log(form.values[name],  parseISO(form.values[name]));
        return (
          <Flatpickr
            value={form.values[name] ? parseISO(form.values[name]) : undefined}
            onChange={([date]) => {
              form.setFieldValue(name, formatISO(date).substring(0, 10))
            }}
            options={{
              dateFormat: I18n.language == 'fr' ? 'd/m/Y' : 'Y-m-d',
              // mode: "range"
            }}
            className='form-control form-control-lg'
            placeholder={placeholder || (I18n.language == 'fr' ? 'jj/mm/aaaa' : 'yyyy-mm-dd')}
            name={name}
          />
        )
      }}
    </Field>
  );
}

export default DateField;
