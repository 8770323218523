import React, {lazy} from 'react';
import clsx from "clsx";
import MegaOptionField from "./MegaOptionField.tsx";
import {ErrorMessage, Field} from "formik";
import OtpInput from "./OtpInput.tsx";
import FormLabel from "./FormLabel.tsx";
import DateField from "./DateField.tsx";
import {countryFlagUrl, extract} from "../../utils";
import FullNameField from "./FullNameField.tsx";
import {useTranslation} from "react-i18next";
import BankRIBField from "./BankRIBField.tsx";
import ContactField from "./ContactField.tsx";
import countries from "../../utils/countries.ts";
import PhoneField from "./PhoneField.tsx";

const DropzoneInput = lazy(() => import('./DropzoneInput.tsx'))
const AddressInput = lazy(() => import('./AddressInput.tsx'))
const QuillInput = lazy(() => import('./QuillInput.tsx'))
const SelectInput = lazy(() => import('./SelectInput.tsx'))
const TagsInput = lazy(() => import('./TagsInput.tsx'))

export interface FormFieldProps {
  label?: string;
  placeholder?: string;
  type: string;
  name: string;
  help?: string;
  options?: Array<{ value: string, label: string, image?: string; description?: string; }>;
  isMulti?: boolean;
  theme?: string;
  required?: boolean;
  size?: string;
  marginBottom?: number;
  touched?: any;
  remote?: (inputValue: any, callback: any) => any;
  readOnly?: boolean;
}

function FormField({touched, errors, type, name, label, help, placeholder, options, required, isSecure, showCountryCode, className, size='lg', marginBottom = 4, orientation = 'vertical', validate, remote, readOnly = false, ...props}: FormFieldProps & {errors: Record<string, any>}) {
  const {t} = useTranslation()
  let input;
  let error = <ErrorMessage name={name} component='div' className='form-text text-danger fs-7'/>
  switch (type) {
    case 'select':
    case 'country':
      if (type == 'country') {
          options = Object.entries(countries).map(([key, value]) => {
            return {
              value: key,
              label: value.name,
              image: countryFlagUrl(key.toLowerCase())
            }
          });
        }
        input = (
          <Field name={name}>
            {({
                field, // { name, value, onChange, onBlur }
                form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                meta,
              }) => {
              return (
                <SelectInput
                  remote={remote}
                  form={form}
                  value={field.value}
                  field={field}
                  name={name}
                  placeholder={placeholder}
                  options={options}
                  size={size}
                  {...props}
                />
              )
            }}
          </Field>
        )
      break;
    case 'date':
      input = (
        <DateField
          label={label}
          name={name}
          placeholder={placeholder}
        />
      );
      break;
    case 'phone':
      input = (
        <PhoneField
          name={name}
          placeholder={placeholder}
          errors={errors}
          touched={touched}
          showCountryCode={showCountryCode}
          {...props}
        />
        // <PhoneField name={name} placeholder={placeholder} touched={touched} errors={errors} showCountryCode={showCountryCode} />
      )
      break;
    case 'tags':
      input = (
        <TagsInput name={name} label={label} placeholder={placeholder} {...props} />
      )
      break;
    case 'textarea':
      input = <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
          return (
            <textarea
              id={`id_${name}`}
              placeholder={placeholder}
              {...field}
              className={clsx(
                'form-control form-control-lg',
                {'is-invalid': form.touched[name] && form.errors[name]},
                {
                  'is-valid': form.touched[name] && !form.errors[name],
                }
              )}
              name={name}
              rows={4}
            />
        )
        }}
      </Field>
      break;
    case 'dropzone':
      input = (
        <DropzoneInput title={placeholder} name={name} {...props} />
      )
      break;
    case 'full_name':
      input = (
        <FullNameField title={placeholder} name={name} errors={errors} touched={touched} readOnly={readOnly}/>
      )
      if (errors.full_name) {
        error = <div className="form-text text-danger fs-7">{Object.values(errors.full_name).join(', ')}</div>
      } else {
        error = null;
      }
      break;
    case 'contact':
      input = (
        <ContactField title={placeholder} name={name} errors={errors} touched={touched} readOnly={readOnly} />
      )
      error = <ErrorMessage name={errors[name] ? name : `${name}.phone_number`} component='div' className='form-text text-danger fs-7'/>
      break;
    case 'bank_rib':
      input = (
        <BankRIBField title={placeholder} name={name} errors={errors} touched={touched}/>
      )
      error = <ErrorMessage name={`${name}.account_number`} component='div' className='form-text text-danger fs-7'/>
      break;
    case 'address':
      input = (
        <AddressInput title={placeholder} name={name} errors={errors} touched={touched} readOnly={readOnly}/>
      )
      error = <ErrorMessage name={`${name}.address1`} component='div' className='form-text text-danger fs-7'/>
      break;
    case 'quill':
      input = <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
          return (
            <QuillInput
              id={`id_${name}`}
              placeholder={placeholder}
              {...form.getFieldProps(name)}
              className={clsx(
                'form-control form-control-transparent',
                {'is-invalid': form.touched[name] && form.errors[name]},
                {
                  'is-valid': form.touched[name] && !form.errors[name],
                }
              )}
              value={form.values[name]}
              onChange={value => form.setFieldValue(name, value)}
              onFocus={() => form.setFieldTouched(name, true)}
              style={{height: 250}}
            />
        )
        }}
      </Field>
      error = <ErrorMessage name={`${name}.address1`} component='div' className='form-text text-danger fs-7'/>
      break;
    case 'megaOption':
      input = (
        <MegaOptionField
          label={label}
          name={name}
          options={options}
          touched={touched}
          errors={errors}
          readOnly={readOnly}
          {...props}
        />
      )
      break;
    case 'pin':
      input = <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
          return (
            <OtpInput
              name={name}
              form={form}
              touched={touched}
              errors={errors}
              isSecure={isSecure}
              inputType={props.inputType}
              length={props.length}
            />
          )
        }}
      </Field>
      if (errors[name]) {
        error = <div className="form-text text-danger fs-7" dangerouslySetInnerHTML={{__html: errors[name]}}></div>
      }
      break;
    case 'radio':
    case 'checkbox':
      if (options) {
        input = (
          <div className={orientation === 'horizontal' ? 'd-flex flex-wrap align-items-center text-gray-600 gap-5' : ''} role="group" aria-labelledby="checkbox-group">
            {options?.map((o, index) => (
              <div key={o.value} className="form-check form-check-custom me-2 mb-2">
                <Field type={type} name={name} value={o.value}
                       className={'form-check-input'} id={`id_${name}_${index}`}/>
                <label htmlFor={`id_${name}_${index}`}
                       className="form-check-label">{o.label}</label>
              </div>
            ))}
          </div>
        )
      } else {
        input = (
          <div className="form-check form-check-custom form-check-solid">
            <Field type={type} name={name} className={'form-check-input me-2'} id={props.id || `id_${name}`} value={props.value}/>
            <span className="d-flex flex-column">
              <FormLabel name={name} marginBottom={0} hideBadge={true} label={label} help={help} id={props.id}/>
              {placeholder && <span className="fs-7 text-muted">{placeholder}</span>}
            </span>
          </div>
        )
      }
      break;
    case 'switch':
      input = <Field name={name}>
        {({
            field, // { name, value, onChange, onBlur }
            form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
            meta,
          }) => {
          return (
            <div className="d-flex flex-stack">
              <div className="me-5">
                {label && <label className="fs-6 fw-semibold">{label}</label>}
                {help && <><br/><label className="fs-7 fw-semibold text-muted">{help}</label></>}
              </div>
              <div className="form-check form-switch form-check-custom form-check-solid">
                <input
                  type='checkbox'
                  className='form-check-input'
                  {...form.getFieldProps(name)}
                  name={name}
                  // value={value}
                  id={`id_${name}`}
                  checked={field.value}
                />
                <label htmlFor={`id_${name}`}
                       className="form-check-label fw-semibold text-muted">{field.value ? t('Yes') : t('No')}</label>
              </div>
            </div>
          )
        }}
      </Field>
      break;
    default:
      let inputMode = 'text';
      let inputType = 'text';
      if (type == 'phone') {
        inputMode = 'tel';
        inputType = 'tel';
      } else if (type == 'number') {
        inputMode = 'number';
      } else if (type == 'amount') {
        inputMode = 'number';
        inputType = 'number';
      }
      input = (
        <Field
          id={`id_${name}`}
          type={inputType}
          name={name}
          placeholder={placeholder}
          className={clsx(
            `form-control form-control-${size}`,
            {'is-invalid': extract(touched, name) && extract(errors, name)},
            {
              'is-valid': extract(touched, name) && !extract(errors, name),
            }
          )}
          inputMode={inputMode}
          validate={validate}
          readOnly={readOnly}
        />
      )
  }
  return (
    <div className={clsx(`fv-row mb-${marginBottom}`, className)}>
      {(label && !(['checkbox', 'radio', 'switch'].includes(type) && !options)) && (
        <FormLabel name={name} label={label} help={help} required={required} />
      )}
      {input}
      {error}
    </div>
  );
}

export default FormField;
