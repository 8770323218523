import React, {useState} from 'react';
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import HTooltip from "./HTooltip.tsx";
import {KTIcon} from "../../helpers";

export interface ActionButtonProps {
  title: React.ReactNode;
  action: { path: string; method?: string }
  className?: string;
  onFinish?: (data: any) => void;
  data?: Record<string, any>;
  confirmation?: {title?: string; message: string}
  tooltip?: string;
}

function ActionButton({title, icon, className = 'btn btn-light fw-bold flex-shrink-0', action, onFinish, data = {}, confirmation, tooltip}: ActionButtonProps) {
  const {t} = useTranslation()
  const [loading, setLoading] = useState(false)
  const btn = (
    <button type="submit" className={clsx(className)} onClick={() => {
      const perform = () => {
        setLoading(true)
        const functions = {post: axios.post, delete: axios.delete, patch: axios.patch, put: axios.put}
        if (action.method == 'delete' && data != null) {
          functions[action.method](action.path, {data}).then(({data}) => {
            setLoading(false)
            onFinish && onFinish(data)
          }).catch(() => {
            setLoading(false)
          })
        } else {
          functions[action.method || 'post'](action.path, data).then(({data}) => {
            setLoading(false)
            onFinish && onFinish(data)
          }).catch(() => {
            setLoading(false)
          })
        }
      }
      if (confirmation) {
        Swal.fire({
          title: confirmation.title || t('Confirmation'),
          text: confirmation.message,
          icon: 'warning',
          buttonsStyling: false,
          confirmButtonText: t('Yes_do_it'),
          customClass: {
            confirmButton: "btn fw-bold btn-light-primary"
          }
        }).then((value) => {
          if (value.isConfirmed) {
            perform()
          }
        })
      } else {
        perform()
      }
    }}>
      {!loading && (
        <>
          {icon && <KTIcon iconName={icon} />}
          {title && title}
        </>
      )}
      {loading && (
        <span className='indicator-progress' style={{display: 'block'}}>
          {className?.indexOf('btn-icon') < 0 && `${t('Please wait')}... `}
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  );
  if (tooltip) {
    return <HTooltip title={tooltip}>{btn}</HTooltip>
  }
  return btn
}

export default ActionButton;
