import React, {useState} from 'react';
import Select from "react-select";
import clsx from "clsx";
import {Field} from "formik";
import {countryFlagUrl, extract} from "../../utils";
import countriesList from "../../utils/countries.ts";
import {InputMask} from '@react-input/mask';

const phoneMasks = {
  AF: "___ ___ ____",
  AL: "__ ___ ____",
  DZ: "_ ___ ____",
  AS: "(___) ___ ____",
  AD: "___ ___ ____",
  AO: "____ ___ ____",
  AI: "(___) ___ ____",
  AG: "(___) ___ ____",
  AR: "_ ___ ____",
  AM: "___ ___ __",
  AW: "__ ___ ____",
  AU: "____ ____",
  AT: "___ ___ ____",
  AZ: "___ ___ ____",
  BS: "(___) ___ ____",
  BH: "___ ___ ____",
  BD: "___ ___ ____",
  BB: "(___) ___ ____",
  BY: "(__) ___-__-__",
  BE: "___ __ __ __",
  BZ: "___ ____",
  BJ: "___ ___ ____",
  BM: "(___) ___ ____",
  BT: "__ ___ ____",
  BA: "___ ___ ____",
  BW: "___ ____",
  BR: "(__) _____-____",
  IO: "___ ____",
  BG: "_ ___ ____",
  BF: "_ ___ ____",
  BI: "___ ____",
  KH: "__ ___ ____",
  CM: "_ __ __ __ __",
  US: "(___) ___ ____",
  CA: "(___) ___ ____",
  CV: "___ ____",
  KY: "(___) ___ ____",
  CF: "_ ___ ____",
  TD: "_ ___ ____",
  CL: "__ ___ ____",
  CN: "___ ____ ____",
  CX: "_ ____ ____",
  CO: "___ ___ ____",
  KM: "___ ____",
  CG: "__ __ _____",
  CK: "__ ___",
  CR: "_ ___ ____",
  HR: "__ ___ ____",
  CU: "___ ___ ____",
  CY: "_ ___ ____",
  CZ: "___ ___ ___",
  DK: "__ __ __ __",
  DJ: "_ ___ ____",
  DM: "____ ___ ____",
  DO: "____ ___ ____",
  EC: "__ ___ ____",
  EG: "___ ___ ____",
  SV: "__ ____ ____",
  GQ: "__ ___ ____",
  ER: "___ ____",
  EE: "___ ___",
  ET: "__ ___ ____",
  FO: "______",
  FJ: "___ ____",
  FI: "__ ___ ____",
  FR: "_ __ __ __ __",
  GF: "___ __ __ __",
  PF: "______",
  GA: "______",
  GM: "___ ____",
  GE: "___ ______",
  DE: "__ ________",
  GH: "__ ___ ____",
  GI: "_____",
  GR: "_ ___ ____",
  GL: "______",
  GD: "(___) ___ ____",
  GP: "___ __ __ __",
  GU: "(___) ___ ____",
  GT: "___ ____",
  GN: "___ ______",
  GW: "___ ____",
  GY: "___ ____",
  HT: "___ ____",
  HN: "___ ____",
  HU: "_ ___ ____",
  IS: "___ ____",
  IN: "___ ___ ____",
  ID: "__ ____ ____",
  IQ: "(__) ___ _____",
  IE: "__ ___ ____",
  IL: "__ ___ ____",
  IT: "__ ___ ____",
  JM: "(___) ___ ____",
  JP: "__ ___ ____",
  JO: "_ ___ ____",
  KZ: "(___) ___ ____",
  KE: "___ ______",
  KI: "_____",
  KW: "____ ____",
  KG: "___ ______",
  LV: "____ ____",
  LB: "__ ___ ___",
  LS: "____ ____",
  LR: "__ ___ ____",
  LI: "___ ____",
  LT: "___ ____",
  LU: "______",
  MG: "___ ____",
  MW: "_________",
  MY: "_ ___ ____",
  MV: "___ ____",
  ML: "____ ____",
  MT: "____ ____",
  MH: "___ ____",
  MQ: "___ __ __ __",
  MR: "___ ____",
  MU: "___ ____",
  YT: "___ __ __ __",
  MX: "___ ___ ____",
  MC: "____ ____",
  MN: "__ ______",
  ME: "__ ______",
  MS: "(___) ___ ____",
  MA: "__ ___ ____",
  MM: "__ ___ ____",
  NA: "__ ______",
  NR: "___ ____",
  NP: "___ ___ ____",
  NL: "__ ___ ____",
  AN: "________",
  NC: "______",
  NZ: "(_) ___ ____",
  NI: "____ ____",
  NE: "__ ______",
  NG: "___ ___ ____",
  NU: "_______",
  NF: "_ __ ___",
  MP: "(___) ___ ____",
  NO: "____ ____",
  OM: "____ ____",
  PK: "___ _______",
  PW: "___ ____",
  PA: "___ ____",
  PG: "___ ____",
  PY: "___ ______",
  PE: "__ _________",
  PH: "__ ___ ____",
  PL: "__ ___ ____",
  PT: "___ ___ ___",
  PR: "+____ ___ ____",
  QA: "____ ____",
  RO: "___ ___ ___",
  RW: "___ ___ ___",
  WS: "___ ____",
  SM: "____ ______",
  SA: "__ ___ ____",
  SN: "__ ___ ____",
  RS: "__ ___ ____",
  SC: "___ ____",
  SL: "__ ______",
  SG: "____ ____",
  SK: "___ ___ ___",
  SI: "_ ___ __ __",
  SB: "_____",
  ZA: "__ ___ ____",
  GS: "_____",
  ES: "___ ___ ___",
  LK: "__ ___ ____",
  SD: "__ ___ ____",
  SR: "______",
  SZ: "_ ___ ____",
  SE: "__ ___ ____",
  CH: "__ ___ ____",
  TJ: "__ ___ ____",
  TH: "_ ___ ____",
  TG: "_ ___ ____",
  TK: "____",
  TO: "___ ____",
  TT: "(___) ___ ____",
  TN: "____ ____",
  TR: "___ ___ ____",
  TM: "__ ______",
  TC: "(___) ___ ____",
  TV: "_____",
  UG: "___ ______",
  UA: "__ ___ ____",
  AE: "__ ______",
  GB: "____ ______",
  UY: "____ ____",
  UZ: "__ ___ ____",
  VU: "_____",
  WF: "__ ____",
  YE: "_ ______",
  ZM: "__ ___ ____",
  ZW: "__ ___ ____",
  AX: "___ ____",
  BO: "___ ___ ____",
  BN: "___ ____",
  CC: "_ ____ ____",
  CD: "__ __ _____",
  CI: "________",
  FK: "_____",
  GG: "(_) ____ ______",
  HK: "____ ____",
  IR: "___ ___ ____",
  KP: "_ ___ ____",
  KR: "_ ___ ____",
  LA: "__ ____ ____",
  LY: "__ ___ ____",
  MO: "____ ____",
  MK: "_ ___ ____",
  FM: "___ ____",
  MD: "___ _____",
  MZ: "___ ______",
  PS: "_ ___ ____",
  PN: "_ ___ ____",
  RE: "___ __ __ __",
  RU: "(___) ___-__-__",
  AC: "______",
  BL: "___ __ __ __",
  SH: "____",
  KN: "(___) ___ ____",
  LC: "(___) ___ ____",
  MF: "___ ______",
  PM: "__ __ __",
  VC: "(___) ___ ____",
  ST: "___ ____",
  SO: "__ ___ ___",
  SJ: "____ ____",
  SY: "__ ___ ____",
  TW: "_ ____ ____",
  TZ: "__ ___ ____",
  TL: "___ ___",
  VE: "___ ___ ____",
  VN: "___ ___ ____",
  VG: "(___) ___ ____",
  VI: "(___) ___ ____"
};

const countries = Object.entries(countriesList).map(([key, value]) => {
  return {
    value: key,
    label: `+${value.code}`,
    image: countryFlagUrl(key.toLowerCase())
  }
});


function PhoneField({name, placeholder, touched, errors, className, showCountryCode = true, ...props}) {
  const [country, setCountry] = useState(countries.find(c => c.value === (props.country || 'CM')));
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        return (
          <div className={clsx('d-flex form-control p-0', {'is-invalid': extract(touched, name) && extract(errors, name)},
            {
              'is-valid': extract(touched, name) && !extract(errors, name),
            }, props.inputClass)}>
            {showCountryCode ? (
              <div className="w-200px">
                <Select
                  className='react-select-styled react-select-transparent'
                  classNamePrefix='react-select'
                  options={countries}
                  // placeholder={'Select country code'}
                  isMulti={false}
                  defaultValue={countries.find(c => c.value === 'CM')}
                  formatOptionLabel={({label, image}) => (
                    <div className='label'>
                      {image ? <img src={image} alt='flag' className='w-20px rounded-circle me-2'/> : null}
                      <span>{label}</span>
                    </div>
                  )}
                  onChange={(e) => {
                    setCountry(e);
                  }}
                />
              </div>
            ) : null}
            {/*<input*/}
            {/*  id={`id_${name}`}*/}
            {/*  type={'tel'}*/}
            {/*  name={name}*/}
            {/*  placeholder={placeholder}*/}
            {/*  className={'form-control form-control-transparent'}*/}
            {/*  {...props}*/}
            {/*/>*/}
            <InputMask
              mask={phoneMasks[country.value]}
              replacement={{ _: /\d/ }}
              className={'form-control form-control-transparent'}
              placeholder={placeholder}
              onChange={event => {
                form.setFieldValue(name, '+' + `${country?.label}${event.target.value}`.replace(/\D/g, ''));
              }}
            />
            {/*<Field*/}
            {/*  // innerRef={inputRef}*/}
            {/*  id={`id_${name}`}*/}
            {/*  type={'tel'}*/}
            {/*  name={name}*/}
            {/*  placeholder={placeholder}*/}
            {/*  className={'form-control form-control-transparent'}*/}
            {/*/>*/}
          </div>
        );
      }}
    </Field>
  );
}

export default PhoneField;
