import React from 'react';
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";
import {KTIcon} from "../../../../_mesomb/helpers";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

function LoginOptions({appleClient, onLogin}) {
  const {t} = useTranslation()
  return (
    <div className='row g-3 mb-9'>
      {/* begin::Col */}
      <div className='col-md-6'>
        <GoogleLogin
          onSuccess={async credentialResponse => {
            await onLogin('google', {
              'client_id': credentialResponse.clientId,
              'id_token': credentialResponse.credential
            })
          }}
          onError={() => {
          }}
          // shape={'pill'}
        />
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <Link
          to={'/auth/email'}
          className='btn btn-flex btn-sm btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
        >
          <KTIcon iconName={'sms'} className={'fs-2x'}/>
          {t('Login by Email')}
        </Link>
        {/* end::Google link */}
      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className='col-md-6'>
        {/* begin::Google link */}
        <Link
          to={'/auth/phone'}
          className='btn btn-flex btn-sm btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100 login-with-phone'
        >
          <KTIcon iconName={'phone'} className={'fs-2x'}/>
          {t('Login by Phone')}
        </Link>
        {/* end::Google link */}
      </div>
      {/* end::Col */}
    </div>
  );
}

const LoginOptionsProvider = ({googleClient, appleClient, onLogin}: { googleClient: string }) => {
  return (
    <GoogleOAuthProvider clientId={googleClient}>
      <LoginOptions appleClient={appleClient} onLogin={onLogin}/>
    </GoogleOAuthProvider>
  )
}

export default LoginOptionsProvider;
