import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function HTooltip({title, id, children}: { title: string, id?: string, children: any }) {
  const renderTooltip = (props) => (
    <Tooltip id={id || 'tooltip'} {...props}>
      {title}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      overlay={renderTooltip}
      delay={{ show: 250, hide: 400 }}
    >
      {children}
    </OverlayTrigger>
  );
}

export default HTooltip;
