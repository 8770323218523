import {AuthModel, UserModel} from "../modules/auth";
import {createAppSlice} from "./createAppSlice.ts";
import {PayloadAction} from "@reduxjs/toolkit";

export interface AuthSliceState {
  meta?: AuthModel
  user?: UserModel
  config?: Record<string, any>
}

const initialState: AuthSliceState = {
  meta: undefined,
  user: localStorage.getItem('ms-user') ? JSON.parse(localStorage.getItem('ms-user') as string) : undefined,
  config: localStorage.getItem('auth-config') ? JSON.parse(localStorage.getItem('auth-config') as string) : undefined,
}

export const authSlice = createAppSlice({
  name: 'auth',
  initialState,
  reducers: create => ({
    setUser: create.reducer((state, action: PayloadAction<UserModel | undefined>) => {
      state.user = action.payload
      if (action.payload) localStorage.setItem('ms-user', JSON.stringify(action.payload))
      else localStorage.removeItem('ms-user')
    }),
    setAuth: create.reducer((state, action: PayloadAction<AuthModel | undefined>) => {
      state.meta = action.payload
      // if (action.payload) saveAuth(action.payload)
      // else removeAuth()
    }),
    setConfig: create.reducer((state, action: PayloadAction<AuthModel | undefined>) => {
      state.config = action.payload
      if (action.payload) localStorage.setItem('auth-config', JSON.stringify(action.payload))
      else localStorage.removeItem('auth-config')
      // if (action.payload) saveAuth(action.payload)
      // else removeAuth()
    }),
    logout: create.reducer((state) => {
      state.meta = undefined;
      state.user = undefined;
    }),
  }),
  selectors: {
    selectUser: auth => auth.user,
    selectAuth: auth => auth.meta,
    selectConfig: auth => auth.config,
  },
});

export const { setUser, setAuth, setConfig, logout } =
  authSlice.actions

export const { selectUser, selectAuth, selectConfig } = authSlice.selectors
