import React from 'react';
import clsx from "clsx";
import {FormFieldProps} from "./FormField.tsx";
import {Field} from "formik";
import {KTIcon} from "../../helpers";

function MegaOptionField({name, options, errors, touched, itemClassName, classCols, action, readOnly = false}: FormFieldProps) {
  return (
    <div id={`id_${name}`}
         className={clsx('row row-cols-1 g-5', classCols || 'row-cols-md-3 row-cols-sm-2', {'is-invalid': touched[name] && errors[name]}, {'is-valid': touched[name] && !errors[name],})} style={{width: '100%'}}>
      {options!.map((o, index) => (
        <div className={itemClassName} key={`option-${index}`}>
          {/*<Field*/}
          {/*  type='radio'*/}
          {/*  className='btn-check'*/}
          {/*  name={name}*/}
          {/*  value={o.value}*/}
          {/*  id={`id_${name}_${index}`}*/}
          {/*  checked={o.value == value}*/}
          {/*/>*/}
          <Field
            type="radio"
            className={'btn-check'}
            name={name}
            value={o.value}
            id={`id_${name}_${index}`}
            readOnly={readOnly}
            disabled={readOnly}
          />
          <label className={'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex align-items-center p-3'} htmlFor={`id_${name}_${index}`}>
            {o.image ? (<span className="svg-icon svg-icon-3x me-2">
              <img src={o.image} height="24" width="24" style={{borderRadius: 5}}/>
            </span>) : null}
            <span className="d-block fw-semibold text-start">
              <span className="text-dark fw-bold d-block fs-4">{o.label}</span>
              {o.description && <span className="text-muted fw-semibold fs-6">{o.description}</span>}
            </span>
          </label>
        </div>
      ))}
      {action && (
        <div className={itemClassName}>
          <label onClick={action.onPress}
                 className={'btn btn-outline btn-outline-dashed btn-active-light-primary d-flex align-items-center p-3'}>
            {action.icon && <KTIcon iconName={action.icon} className={'fs-2x'}/>}{' '}{action.label}
          </label>
        </div>
      )}
    </div>
  );
}

export default MegaOptionField;
