import React from 'react';
import {Field} from "formik";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import {extract} from "../../utils";

function FullNameField({name, errors, touched, readOnly = false}) {
  const {t} = useTranslation()

  return (
    <Field name={name}>
      {({ field, form, meta: {value} }) => {
        const touched = form.touched[`${name}_first_name`] || form.touched[`${name}_last_name`];
        return (
          <div
            className={clsx('form-control form-control-lg p-0 mb-4',
              {
                'is-invalid': touched && extract(errors, name),
                'is-valid': touched && !extract(errors, name),
              })}>
            <div className="d-flex">
              <input
                id={`id_${name}_last_name`}
                type={'text'}
                name={`${name}.last_name`}
                placeholder={t('Last Name')}
                className={clsx(
                  `form-control form-control-transparent`,
                )}
                value={value?.last_name}
                onChange={(e) => {
                  form.setFieldValue(`${name}.last_name`, e.target.value)
                }}
                // onBlur={field.onBlur}
                onFocus={() => {
                  form.setFieldTouched(`${name}_last_name`, true)
                }}
                readOnly={readOnly}
              />
              <input
                id={`id_${name}_first_name`}
                type={'text'}
                name={`${name}.first_name`}
                placeholder={t('First Name')}
                className={clsx(
                  `form-control form-control-transparent`,
                )}
                value={value?.first_name}
                onChange={(e) => {
                  form.setFieldValue(`${name}.first_name`, e.target.value)
                }}
                // onBlur={field.onBlur}
                onFocus={() => {
                  form.setFieldTouched(`${name}_first_name`, true)
                }}
                readOnly={readOnly}
              />
            </div>
          </div>
        )
      }}
    </Field>
  )
}

export default FullNameField;
