/* eslint-disable @typescript-eslint/no-explicit-any */
// import {AuthModel, UserModel} from './_models.ts'
import {CookieComponent} from "../../../../_mesomb/assets/ts/components/_CookieComponent.ts";
import {setUser} from "../../../store/auth.ts";
import pkg from '../../../../../package.json' assert {type: 'json'}
// import { setUser } from '../../../store/auth.ts';
//
// const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
// const getAuth = (): AuthModel | undefined => {
//   if (!localStorage) {
//     return
//   }
//
//   const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
//   if (!lsValue) {
//     return
//   }
//
//   try {
//     const auth: AuthModel = JSON.parse(lsValue) as AuthModel
//     if (auth) {
//       // You can easily check auth_token expiration also
//       return auth
//     }
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
//   }
// }
//
// const saveAuth = (auth: AuthModel) => {
//   if (!localStorage) {
//     return
//   }
//
//   try {
//     const lsValue = JSON.stringify(auth)
//     localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
//   }
// }
//
// const removeAuth = () => {
//   if (!localStorage) {
//     return
//   }
//
//   try {
//     localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
//   } catch (error) {
//     console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
//   }
// }
//
// const USER_LOCAL_STORAGE_KEY = 'ms-user'
// const getUser = (): UserModel | undefined => {
//   if (!localStorage) {
//     return
//   }
//
//   const lsValue: string | null = localStorage.getItem(USER_LOCAL_STORAGE_KEY)
//   if (!lsValue) {
//     return
//   }
//
//   try {
//     const user: UserModel = JSON.parse(lsValue) as UserModel
//     if (user) {
//       // You can easily check user_token expiration also
//       return user
//     }
//   } catch (error) {
//     console.error('USER LOCAL STORAGE PARSE ERROR', error)
//   }
// }
//
// const saveUser = (user: UserModel) => {
//   if (!localStorage) {
//     return
//   }
//
//   try {
//     const lsValue = JSON.stringify(user)
//     localStorage.setItem(USER_LOCAL_STORAGE_KEY, lsValue)
//   } catch (error) {
//     console.error('USER LOCAL STORAGE SAVE ERROR', error)
//   }
// }
//
// const removeUser = () => {
//   if (!localStorage) {
//     return
//   }
//
//   try {
//     localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
//   } catch (error) {
//     console.error('USER LOCAL STORAGE REMOVE ERROR', error)
//   }
// }

export function setupAxios(axios: any, store?: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.defaults.baseURL = `${import.meta.env.VITE_APP_BACKEND_HOST}/api/v1.1/`
  axios.defaults.xsrfHeaderName = "X-CSRFToken"
  axios.defaults.xsrfCookieName = "csrftoken"
  // axios.defaults.headers['X-CSRFToken'] = CookieComponent.get('csrftoken')
  if (store?.getState().auth?.user?.locale) {
    axios.defaults.headers['Accept-Language'] = store.getState().auth?.user?.locale
  }
  axios.defaults.headers['X-MeSomb-Source'] = `Web/${pkg.version}`
  axios.interceptors.request.use(
    (config: {headers: {'X-Session-Token': string}, withCredentials: boolean}) => {
      config.withCredentials = true
      config.headers['X-CSRFToken'] = CookieComponent.get('csrftoken')
      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(function (response) {
    return response
  }, async function (error) {
    if (error.response?.data?.code === 'not_authenticated' && !location.pathname.startsWith('/auth')) {
      axios.delete(setAuthUrl('auth/session')).finally(() => {
        store.dispatch(setUser(undefined))
        window.location.href = '/auth'
      });
      return;
    }
    return Promise.reject(error)
  })
}

export function setAuthUrl(endpoint: string) {
  return `${import.meta.env.VITE_APP_BACKEND_HOST}/api/auth/browser/v1/${endpoint}`
}

// export {getAuth, saveAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY, USER_LOCAL_STORAGE_KEY, saveUser, getUser, removeUser}
