import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import i18n from "../../../../_mesomb/i18n";
import ButtonIndicator from "../../../../_mesomb/partials/components/ButtonIndicator.tsx";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {setAuthUrl} from "../core/AuthHelpers.ts";
import {Helmet} from "react-helmet";

const initialValues = {
  password: '',
  password2: '',
}

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Field_is_required')),
  password2: Yup.string()
    .min(3, i18n.t('Minimum_characters', {min: 3}))
    // .max(50, 'Maximum 50 symbols')
    .required(i18n.t('Password confirmation is required'))
    .oneOf([Yup.ref('password')], i18n.t('Your passwords didn\'t match')),
})

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {key} = useParams()
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting, setErrors}) => {
      setSubmitting(true)
      axios.post(setAuthUrl('auth/password/reset'), {key, password: values.password}).then(() => {
        setHasErrors(false)
        setLoading(false)
        navigate('/auth/login');
      }).catch(error => {
        setSubmitting(false)
        const response = error.response.data;
        if (response.errors) {
          const errors = response.errors.reduce((acc, item) => ({...acc, [item.param || 'password']: item.message}), {})
          setErrors(errors)
          if (errors.key) {
            setHasErrors(true)
            setStatus(errors.key)
          }
        } else if (response.meta) {
          navigate('/auth/login');
        }
      })
      // setLoading(true)
      // setHasErrors(undefined)
      // setTimeout(() => {
      //   requestPassword(values.email)
      //     .then(() => {
      //       setHasErrors(false)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setHasErrors(true)
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  // useEffect(() => {
  //   axios.get(setAuthUrl('auth/password/reset'), {headers: {'X-Password-Reset-Key': key}}).then(({data}) => {
  //     console.log('datadata', data)
  //   })
  // }, [key]);

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-gray-900 fw-bolder mb-3'>{t('Forgot Password')} ?</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-500 fw-semibold fs-6'>
            {t('Enter your email to reset your password.')}
          </div>
          {/* end::Link */}
        </div>

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {formik.status || t('Sorry, looks like there are some errors detected, please try again.')}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{t('Reset_password_link_sent')}</div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group Password */}
        <div className='fv-row mb-8' data-kt-password-meter='true'>
          <div className='mb-1'>
            <label className='form-label fw-bolder text-gray-900 fs-6'>{t('Enter a Password')}</label>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder={t('Your password')}
                autoComplete='off'
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.password && formik.errors.password,
                  },
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className='text-muted'>
            Use 8 or more characters with a mix of letters, numbers & symbols.
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className='fv-row mb-5'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{t('Confirm Password')}</label>
          <input
            type='password'
            placeholder={t('Confirm your password')}
            autoComplete='off'
            {...formik.getFieldProps('password2')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password2 && formik.errors.password2,
              },
              {
                'is-valid': formik.touched.password2 && !formik.errors.password2,
              }
            )}
          />
          {formik.touched.password2 && formik.errors.password2 && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password2}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <ButtonIndicator
            type={'submit'}
            label={t('Submit')}
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
            className='btn btn-primary me-4'
          />
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-light'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t('Cancel')}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
      <Helmet>
        <title>{t('Reset Password')}</title>
      </Helmet>
    </>
  )
}
